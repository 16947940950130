import './InformationBannerPopup.less'

import classNames from 'classnames'
import React from 'react'

import { InformationBanner as InformationBannerData, InformationBannerType } from '../../../requests'
import { DatacIcon } from '../../common'

enum InformationBannerTypeIcons {
  'ERROR' = 'alertCircle',
  'WARNING' = 'alertTriangle',
  'INFORMATION' = 'stars'
}

interface Props {
  informationBanner: InformationBannerData
  closeInformationBanner: (token: string) => void
}

export const InformationBannerPopup: React.FC<Props> = ({ informationBanner, closeInformationBanner }) => {
  return (
    <div
      className={classNames('information-banner', {
        'information-banner--error': informationBanner.type === InformationBannerType.Error,
        'information-banner--information': informationBanner.type === InformationBannerType.Information,
        'information-banner--warning': informationBanner.type === InformationBannerType.Warning
      })}
    >
      <div className="information-banner__content">
        <DatacIcon
          size="small"
          name={InformationBannerTypeIcons[informationBanner.type]}
          className="information-banner__icon"
        />
        <div className="information-banner__text">{informationBanner.text}</div>
        {informationBanner.link && (
          <a href={informationBanner.link} target="_blank" rel="noreferrer" className="information-banner__link">
            {informationBanner.linkName}
          </a>
        )}
      </div>
      <button
        type="button"
        className="raw information-banner__close"
        onClick={() => closeInformationBanner(informationBanner.token)}
      >
        <DatacIcon name="x" type="white-on-semi-transparent" />
      </button>
    </div>
  )
}
