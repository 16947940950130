import './BasicLayout.less'

import { Tooltip } from 'antd'
import classNames from 'classnames'
import { Link } from 'gatsby-plugin-react-intl'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../hooks'
import { AccountType, AclFeature, InformationBanner } from '../../requests'
import { routes } from '../../routes'
import { UserContext } from '../auth'
import { DatacIcon, DatacIconName, DatacMessage } from '../common'
import { BottomPopup } from './BottomPopup'
import { InformationBannerPopup } from './InformationBanner'

interface MenuOptionProps {
  iconName: DatacIconName
  route: string
  active?: boolean
  blockMessage?: () => void
  tooltip: string
}

const MenuOption: React.FC<MenuOptionProps> = ({ iconName, route, active = true, blockMessage, tooltip }) => (
  <Tooltip title={tooltip} placement="right">
    <Link
      to={active ? route : '#'}
      getProps={({ isPartiallyCurrent }) => ({ className: isPartiallyCurrent ? 'basic-layout__link-active' : '' })}
      onClick={e => {
        if (!active) {
          e.preventDefault()
          blockMessage()
        }
      }}
    >
      <div className="basic-layout__menu-option" data-cy={iconName}>
        <DatacIcon raw name={iconName} className="basic-layout__menu-option-icon" />
      </div>
    </Link>
  </Tooltip>
)

interface Props {
  children: React.ReactNode
  hideMenu?: boolean
  blockNavigation?: boolean
  accountType?: AccountType
}

export const BasicLayout: React.FC<Props> = ({
  children,
  hideMenu = false,
  blockNavigation = false,
  accountType = AccountType.User
}) => {
  const intl = useScopedIntl('')
  const { user } = useContext(UserContext)
  const [informationBanner, setInformationBanner] = useState<InformationBanner>(null)

  useEffect(() => {
    if (user.shouldShowInformationBanner()) {
      setInformationBanner(user.getInformationBanner())
    }
  }, [user])

  const displayEconsultNavigationBlocked = () =>
    DatacMessage.warning(intl('action_blocked.title'), intl('action_blocked.description'))

  const isSubjectRepositoryAvailable = user.canAccess(AclFeature.SubjectRepository) && user.isSubjectRepositoryEnabled
  const isRecruitmentAvailable = user.canAccess(AclFeature.Recruitment) && user.isRecruitmentEnabled
  const isPaymentsOrdersAvailable =
    user.isRecruitmentEnabled && user.isPaymentsEnabled && user.canAccess(AclFeature.PaymentOrders)
  const isSideBySideAvailable = user.canAccess(AclFeature.SideBySide) && user.isSideBySideEnabled
  const isCalendarAvailable = user.isCalendarEnabled && user.canAccess(AclFeature.Calendar)

  return (
    <>
      {informationBanner && (
        <InformationBannerPopup
          informationBanner={informationBanner}
          closeInformationBanner={() => {
            user.closeInformationBanner(informationBanner.token)
            setInformationBanner(null)
          }}
        />
      )}
      <div
        className={classNames('basic-layout', {
          'basic-layout__subject': accountType === AccountType.Subject,
          'basic-layout--information-banner': !!informationBanner
        })}
      >
        <div className="basic-layout__menu">
          <DatacIcon raw name="datac" className="basic-layout__datac-icon" />
          {!hideMenu && (
            <>
              <div className="basic-layout__menu-options">
                <MenuOption iconName="fileText" route={routes.studies} tooltip={intl('studies.title')} />
                {isRecruitmentAvailable && (
                  <MenuOption iconName="filePlus" route={routes.recruitment} tooltip={intl('recruitment.title')} />
                )}{' '}
                {isSideBySideAvailable && (
                  <MenuOption iconName="columns" route={routes.sideBySide} tooltip={intl('side_by_side.title')} />
                )}
                {isSideBySideAvailable && (
                  <MenuOption
                    iconName="database"
                    route={routes.products}
                    tooltip={intl('side_by_side.products.table.title')}
                  />
                )}
                {isPaymentsOrdersAvailable && (
                  <MenuOption iconName="dollar" route={routes.paymentOrders} tooltip={intl('payment_orders.title')} />
                )}
                {isSubjectRepositoryAvailable && (
                  <MenuOption
                    iconName="users"
                    route={routes.subjectRepository}
                    tooltip={intl('subject_repository.title')}
                  />
                )}
                {isCalendarAvailable && (
                  <MenuOption iconName="calendar" route={routes.calendar} tooltip={intl('calendar.title')} />
                )}
                <MenuOption
                  iconName="settings"
                  route={routes.settingsPersonalDetails}
                  active={!blockNavigation}
                  blockMessage={displayEconsultNavigationBlocked}
                  tooltip={intl('studies.settings.title')}
                />
              </div>
              <BottomPopup blockNavigation={blockNavigation} />
            </>
          )}
        </div>
        <div className="basic-layout__content">{children}</div>
      </div>
    </>
  )
}
